<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Master from "@/apis/Master";
/**
 * Product Detail Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "PRODUCT DETAILS",
      productData:"",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "PRODUCT LIST",
          href: "/products"
        },
        {
          text: "PRODUCT DETAILS",
          active: true
        }
      ],
    };
  },
  mounted() {
    Master.productDetails({
      productId: localStorage.getItem('productId')
    }).then((res) => {
      console.log(res)
      this.productData = res.data.data;
    })
  },
  methods: {
    /**
     * Selected image shows
     */
    productIdremoved(){
      localStorage.removeItem('productId')
    },
    imageShow(event,data) {
      const image = event.target.src;
      const expandImg = document.getElementById("expandedImg-"+data);
      expandImg.src = image;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
        <div class="card-body">
          <span class="font-size-6">{{productData.brand}}</span>
          <h5 class="mt-1 mb-3">{{productData.title}}</h5>
        </div>
        </div>
        <div class="card" v-for="(data,index) in productData.variants" :key="index">

          <div class="card-body">
            <div class="row">
              <div class="col-xl-5">

                <div class="product-detail">
                  <div class="row">
                    <div class="col-3">
                      <div
                          class="nav flex-column nav-pills"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                      >
                        <a
                            class="nav-link"
                            id="product-1-tab"
                            data-toggle="pill"
                            @click="imageShow($event,index)"
                            role="tab"
                            v-for="(image,index1) in data.images" :key="index1"
                        >
                          <img
                              :src="image.imageUrl"
                              alt
                              class="img-fluid mx-auto d-block tab-img rounded"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="col-md-8 col-9">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="product-1" role="tabpanel">
                          <div class="product-img" v-if="productData.variants[index].images[index] != null">
                            <img
                                :id="'expandedImg-'+index"
                                :src="productData.variants[index].images[index].imageUrl"
                                alt
                                class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="mt-4 mt-xl-3" >
                  <ul class="list-unstyled product-desc-list" style="text-transform: uppercase !important;list-style-type: none;">
                    <li>
                      <i class=" mr-1 align-middle"></i><p class="badge font-size-12">PRODUCT TYPE : {{productData.listSubCategory}}</p>
                    </li>
                    <li>
                      <i class=" mr-1 align-middle"></i><p class="badge font-size-12">SKU : {{productData.variants[index].sku}}</p>
                    </li>
<!--                    <li>-->
<!--                      <i class=" mr-1 align-middle"></i><p class="badge font-size-12"> COLOR : {{productData.variants[index].color ? productData.variants[index].color : "NA"}}</p>-->
<!--                    </li>-->
                    <li>
                      <i class=" mr-1 align-middle"></i><p class="badge font-size-12"> PRICE : QAR {{productData.variants[index].price.toLocaleString()}}</p>
                    </li>
                    <li>
                      <i class=" mr-1 align-middle"></i><p class="badge font-size-12"> DIMENSION : {{productData.variants[index].dimensions}}</p>
                    </li>
                    <li>
                      <i class="mr-1 align-middle"></i><p class="badge font-size-12"> COST PER ITEM : QAR {{productData.variants[index].costPerItem.toLocaleString()}}</p>
                    </li>
                    <li>
                      <i class="mr-1 align-middle"></i><p class="badge font-size-12"> OFFER PRICE : QAR {{productData.variants[index].compareAtPrice.toLocaleString()}}</p>
                    </li>
<!--                    <li>-->
<!--                      <i class="mr-1 align-middle"></i><p class="badge font-size-12"> SIZE : {{productData.variants[index].size ? productData.variants[index].size :"NA" }}</p>-->
<!--                    </li>-->
                    <li>
                      <i class="mr-1 align-middle"></i><p class="badge font-size-12"> AVAILABLE STOCKS : {{productData.variants[index].stock}}</p>
                    </li>
                    <li v-if="productData.variants[index].preOrder == 1">
                      <i class="mr-1 align-middle"></i><p class="badge font-size-12"> PRE-ORDER: YES</p>
                    </li>
                    <li v-else>
                      <i class="mr-1 align-middle"></i><p class="badge font-size-12"> PRE-ORDER: NO</p>
                    </li>
                    <li v-if="productData.variants[index].catBlock">

                      <div>
                        <a target="_blank" :href="productData.variants[index].catBlock"><img :src="productData.variants[index].catBlock"  class="avatar-lg"  /></a>
                      </div>
                      <i class="mr-1 align-middle"></i><p class="badge font-size-12">CAD BLOCK</p>
                    </li>
                  </ul>




                </div>
              </div>
            </div>
            <!-- end row -->




          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body"  style="text-transform: uppercase !important;">
            <div class="row">
              <div class="mt-4">
                <h5 class="font-size-14 mb-3">PRODUCT DETAILS</h5>
                <div class="product-desc">
                  <b-tabs class="nav-tabs-custom" content-class="border border-top-0 p-4">
                    <b-tab title="DESCRIPTION">
                      <p v-html="productData.productDescription"  style="text-transform: uppercase !important" class="font-size-12"></p>
                    </b-tab>
                    <b-tab title="SPECIFICATIONS">
                      <div class="table-responsive">
                        <table class="table table-nowrap mb-0">
                          <tbody>
                          <tr v-for="(specification,index) in productData.specifications" :key="index">
                            <th scope="row" style="width: 400px; text-transform: uppercase !important;"><p class="badge font-size-12">{{specification.specificationName}}</p></th>
                            <td><p class="badge font-size-12">{{specification.specificationValue}}</p></td>
                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </b-tab>
                    <b-tab title="FILTERS">
                      <div class="table-responsive">
                        <table class="table table-nowrap mb-0">
                          <tbody>
                          <tr  v-for="(filter,index) in productData.filters" :key="index">
                            <th scope="row" style="width: 400px; text-transform: uppercase !important;"><p class="badge font-size-12">{{filter.filterName}}</p></th>
                            <td><p class="badge font-size-12">{{filter.filter_value}}</p></td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>
                    <b-tab title="SEO">
                      <div class="table-responsive">
                        <table class="table table-nowrap mb-0">
                          <tbody>
                          <tr v-if="productData.seoTitle">
                            <th scope="row" style="width: 400px;"><p class="badge font-size-12">SEO TITLE</p></th>
                            <td><p class="badge font-size-12">{{productData.seoTitle}}</p></td>
                          </tr>
                          <tr v-if="productData.seoDescription">
                            <th scope="row"><p class="badge font-size-12">SEO DESCRIPTION</p></th>
                            <td><p class="badge font-size-12">{{productData.seoDescription}}</p></td>
                          </tr>
                          <tr  v-if="productData.seoKeywords">
                            <th scope="row"><p class="badge font-size-12">SEO KEYWORDS</p></th>
                            <td><p class="badge font-size-12">{{productData.seoKeywords}}</p></td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div align="right">
          <router-link to="/products">
            <a href="javascript:void(0);" class="btn btn-dark btn-sm mb-4 ml-1" @click="productIdremoved">
            <i class="mdi mdi-arrow-left mr-2"></i> BACK
          </a></router-link>
        </div>

      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>